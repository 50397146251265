import { Component, OnInit, OnChanges, Input } from '@angular/core'
import { TranslateService, TranslateModule } from '@ngx-translate/core'
import { DateTranslatorService, EliqNumberPipe } from '@eliq/core'
import { ConsumptionHelperService } from '@eliq/core'
import { DateHelper } from '@eliq/util'

import { ConsumptionCardConfigService } from '../config/consumption-card-config.service'
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { CapitalizePipe } from '@eliq/core/pipes/capitalize.pipe'
import { CostsPopperComponent } from '../costs-popper/costs-popper.component'
import { FlexibleButtonComponent } from '@eliq/ui/common/components/buttons/flexible-button/flexible-button.component'
import { RouterLink } from '@angular/router'
import { CardComponent } from '@eliq/ui/common/components/card/card.component'
import { NgIf, AsyncPipe } from '@angular/common'
import {
	NgxPopperjsModule,
	NgxPopperjsPlacements,
	NgxPopperjsTriggers,
} from 'ngx-popperjs'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { EnvironmentService } from '@eliq/data-access'
import { InfoButtonComponent } from "@eliq/ui/common/components/buttons/info-button/info-button.component";

@Component({
	selector: 'eliq-consumption',
	templateUrl: './consumption.component.html',
	styleUrls: ['./consumption.component.scss'],
	standalone: true,
	imports: [
    NgIf,
    CardComponent,
    TranslateModule,
    RouterLink,
    NgxPopperjsModule,
    FlexibleButtonComponent,
    CostsPopperComponent,
    AsyncPipe,
    CapitalizePipe,
    ImgSrcPipe,
    InlineSVGModule,
    InfoButtonComponent
],
})
export class ConsumptionComponent implements OnInit, OnChanges {
	/**
	 * This component isn't dumb at the moment. Need to make it dumber (as in remove the injections pretty much)
	 */

	@Input() date: Date
	@Input() toDate: Date
	@Input() fuels: string[]
	@Input() units: string[]
	@Input() consumptionCost: Map<string, number[]>
	@Input() consumptionEnergy: Map<string, number[]>
	@Input() forecastCost: Map<string, number[]>
	@Input() forecastEnergy: Map<string, number[]>
	@Input() forecastCostNextMonth: Map<string, number[]>
	@Input() forecastEnergyNextMonth: Map<string, number[]>
	@Input() previousPeriodConsumption: number // the unit will be inferred by the presentational component
	@Input() similarHomesConsumption: number
	@Input() isCostHidden: boolean
	@Input() isEstimated = false

	public loaded = false

	// variables for use in the template
	public title = ''
	public bigValue: number
	public popperTrigger = NgxPopperjsTriggers.click
	public popperPlacement = NgxPopperjsPlacements.BOTTOM
	public smallValue: number
	public bigValueForecast = ''
	public smallValueForecast = ''
	public bigValueUnit: 'cost' | 'energy'
	public smallValueUnit: 'cost' | 'energy'
	public enableInfoPopper = false

	public prevPeriodComparison = {}
	public simHomesComparison = {}

	public comparisonBounds = ''
	public showSoFar = false
	public showNextMonthForecast = false

	// internal variables
	public prevMonth = ''
	public currMonth = ''
	public nextMonth = ''
	private setupDone = false

	private dateCalc = DateHelper.getInstance()

	public configShowSoFar$ = this.config.showSoFar()

	constructor(
		private translator: TranslateService,
		private dateTranslator: DateTranslatorService,
		private consHelp: ConsumptionHelperService,
		private eliqNumberPipe: EliqNumberPipe,
		private config: ConsumptionCardConfigService,
		private env: EnvironmentService,
	) {}

	ngOnInit() {
		this.setup()
		this.setupDone = true
	}

	ngOnChanges() {
		if (this.setupDone) {
			this.setup()
		}
	}

	private setup() {
		this.clearValues() // for sanity's sake

		this.currMonth = this.dateTranslator.getTranslatedMonth(
			this.date.getMonth(),
			false,
			true,
		)
		this.nextMonth = this.dateTranslator.getTranslatedMonth(
			this.dateCalc.addOfPeriod('month', 1, this.date).getMonth(),
			false,
			true,
		)
		this.prevMonth = this.dateTranslator.getTranslatedMonth(
			this.dateCalc.subOfPeriod('month', 1, this.date).getMonth(),
			false,
			true,
		)

		if (this.consumptionCost) {
			// we have cost and energy values
			if (this.units[0] === 'cost') {
				this.bigValue = this.consHelp.summarizeArraysInMap(this.consumptionCost)
				this.bigValueUnit = 'cost'
				this.comparisonBounds = this.getComparisonBounds(this.consumptionCost)
			} else {
				this.smallValue = this.consHelp.summarizeArraysInMap(
					this.consumptionCost,
				)
				this.smallValueUnit = 'cost'
			}
		}

		if (this.consumptionEnergy) {
			if (this.bigValue) {
				this.smallValue = this.consHelp.summarizeArraysInMap(
					this.consumptionEnergy,
				)
				this.smallValueUnit = 'energy'
			} else {
				this.bigValue = this.consHelp.summarizeArraysInMap(
					this.consumptionEnergy,
				)
				this.bigValueUnit = 'energy'
				this.comparisonBounds = this.getComparisonBounds(this.consumptionEnergy)
			}
		}

		if (this.forecastCost || this.forecastEnergy) {
			if (this.forecastCost) {
				this.showSoFar = true
				if (this.bigValueUnit === 'cost') {
					this.bigValueForecast = this.costPipe(
						this.consHelp.summarizeArraysInMap(this.forecastCost) +
							(this.bigValue || 0),
					)
				} else {
					this.bigValueForecast = this.energyPipe(
						this.consHelp.summarizeArraysInMap(this.forecastEnergy) +
							(this.bigValue || 0),
					)
				}
			}

			if (this.forecastEnergy) {
				this.showSoFar = true
				if (this.bigValueForecast) {
					if (this.bigValueUnit === 'cost') {
						this.smallValueForecast = this.energyPipe(
							this.consHelp.summarizeArraysInMap(this.forecastEnergy) +
								(this.smallValue || 0),
						)
					} else {
						this.smallValueForecast = this.costPipe(
							this.consHelp.summarizeArraysInMap(this.forecastCost) +
								(this.smallValue || 0),
						)
					}
				} else {
					this.bigValueForecast = this.energyPipe(
						this.consHelp.summarizeArraysInMap(this.forecastEnergy) +
							(this.bigValue || 0),
					)
				}
			}
		} else {
			// we dont have forecast for the current month, so we might have forecast for the next month instead!
			if (this.forecastCostNextMonth) {
				this.showNextMonthForecast = true
				this.bigValueForecast = this.getCostString(this.forecastCostNextMonth)
			}

			if (this.forecastEnergyNextMonth) {
				this.showNextMonthForecast = true
				if (this.bigValueForecast) {
					this.smallValueForecast = this.getEnergyString(
						this.forecastEnergyNextMonth,
					)
				} else {
					this.bigValueForecast = this.getEnergyString(
						this.forecastEnergyNextMonth,
					)
				}
			}
		}

		if (this.bigValue) {
			// bigValue is what we compare simHomes value to and prevPeriod value to
			// if we have a small value then we know that bigValue is of unit cost, and smallValue is of type energy.
			// if we only have a bigValue and no smallValue, then we know that we're only showing
			this.prevPeriodComparison = this.getComparison(
				this.bigValue,
				this.previousPeriodConsumption,
				this.bigValueUnit,
				'last_period',
			) as any

			if (this.bigValueUnit === 'energy') {
				// if the bigvalue unit is energy, then we know that of course have energy values, and then we try to get similar homes comparison
				this.simHomesComparison = this.getComparison(
					this.bigValue,
					this.similarHomesConsumption,
					'energy',
					'similar_homes',
				) as any
			} else if (this.smallValue) {
				// if bigvalue is cost, and we have a small value, then we know that small value is energy, so we can try to create a similar homes comparison with smallvalue
				this.simHomesComparison = this.getComparison(
					this.smallValue,
					this.similarHomesConsumption,
					'energy',
					'similar_homes',
				) as any
			}
		} else {
			// show no data message
		}

		this.title = this.translator.instant(
			'home_consumption_summary_month.your_consumption_in_month',
			{ month: this.currMonth },
		)

		// For santander we want to always show the popper because they included additional info in it.
		// The task said that we should do it for all integrationless clients, since this is only Santander now, we could also enable it just for them.
		// It is likely that other similar clients in the future will need the same functionality so I'll do it according to the task.
		if (this.fuels?.length > 1 || this.env.isIntegrationless()) {
			this.enableInfoPopper = true
		}

		this.loaded = true
	}

	/**
	 *
	 * @param array Current data array
	 * @param data_from Current data_from
	 * @param data_to Current data_to
	 * @returns Range string for last month (printed without year),
	 * if date of data_to is greater than the number of days in last month then last date is used for last month
	 */
	private getLastMonthDateRangeString(
		array: number[],
		data_from: Date,
		data_to?: Date,
	): string {
		const lastIndex: number | Date =
			this.consHelp.getIndexOfLastEntryWithValue(array) + 1

		let endDate =
				data_to &&
				new Date(
					data_to.getFullYear(),
					data_to.getMonth(),
					data_to.getDate() - 1,
				),
			startDate = data_from

		if (data_to) {
			let endDateTest = null as unknown as Date
			if (endDate) {
				endDateTest = new Date(
					endDate.getFullYear(),
					endDate.getMonth() - 1,
					endDate.getDate(),
				)
			}
			// data_to's date is more than the number of days in previous month
			if (endDateTest.getMonth() === endDate?.getMonth()) {
				// so set it to last date in previous month
				endDate = new Date(endDate.getFullYear(), endDate.getMonth(), 0)
			} else {
				endDate = endDateTest
			}
		} else if (lastIndex === 1) {
			// If no data_to is specified (it's monthly resolution) just say last day of month.
			endDate = new Date(endDate!.getFullYear(), endDate!.getMonth(), 0)
		}

		startDate = new Date(
			startDate.getFullYear(),
			startDate.getMonth() - 1,
			startDate.getDate(),
		)

		return this.dateTranslator.getRangeInYearString(startDate, endDate!, false)
	}

	private getArrayWithMostData(consumption: Map<string, number[]>): number[] {
		let biggestArray: number[] = []
		let biggestArrayDataCount: number
		consumption.forEach((array) => {
			const i = this.consHelp.getIndexOfLastEntryWithValue(array) || 0
			if (!biggestArray || i > biggestArrayDataCount) {
				biggestArray = array
				biggestArrayDataCount = i
			}
		})
		return biggestArray
	}

	private getComparisonBounds(consumption: Map<string, number[]>): string {
		return this.getLastMonthDateRangeString(
			this.getArrayWithMostData(consumption),
			this.date,
			this.toDate,
		)
	}

	private clearValues() {
		this.loaded = false
		this.title = undefined as any

		this.bigValue = undefined as any
		this.smallValue = undefined as any
		this.bigValueForecast = undefined as any
		this.smallValueForecast = undefined as any

		this.showSoFar = false
		this.showNextMonthForecast = false

		this.currMonth = undefined as any
		this.prevMonth = undefined as any
		this.nextMonth = undefined as any

		this.simHomesComparison = undefined as any
		this.prevPeriodComparison = undefined as any
		this.comparisonBounds = undefined as any
	}

	private getEnergyString(map: Map<string, number[]>): string {
		return this.energyPipe(this.consHelp.summarizeArraysInMap(map))
	}

	private getCostString(map: Map<string, number[]>): string {
		return this.costPipe(this.consHelp.summarizeArraysInMap(map))
	}

	// used by the template
	public getBigValueString(): string {
		if (!this.bigValue) {
			return ''
		}

		if (this.bigValueUnit === 'cost') {
			return this.costPipe(this.bigValue)
		} else {
			return this.energyPipe(this.bigValue)
		}
	}

	// used by the template
	public getSmallValueString(): string {
		if (!this.smallValue) {
			return ''
		}

		if (this.smallValueUnit === 'cost') {
			return this.costPipe(this.smallValue)
		} else {
			return this.energyPipe(this.smallValue)
		}
	}

	/**
	 *
	 * @param yourValue your value, which you compare to something else
	 * @param comparisonValue the value you are comparing to.
	 * @param unit cost or energy, used to get the correct translation
	 * @param type last_period or similar_homes
	 * @returns a string or, if any of the values to be compared are either null or 0, returns undefined
	 */
	private getComparison(
		yourValue: number,
		comparisonValue: number,
		unit: string,
		type: 'last_period' | 'similar_homes',
	) {
		if (
			comparisonValue == null ||
			yourValue == null ||
			yourValue === 0 ||
			comparisonValue === 0
		) {
			return undefined
		}

		const diff = Math.round(
			(100 * (yourValue - comparisonValue)) / comparisonValue,
		)
		const diffType = diff <= 0 ? 'less' : 'more'
		const percentage = (diffType === 'less' ? diff * -1 : diff) + '%'

		let key = ''
		if (unit === 'energy') {
			key = `home_consumption_summary_month.you_consumed_${diffType}_compared_to_${type}`
		} else {
			// "cost"
			key = `home_consumption_summary_month.you_paid_${diffType}_compared_to_${type}`
		}

		return {
			key: key,
			params: {
				amount: percentage,
				month: this.prevMonth,
			},
		}
	}

	private costPipe(cost: number): string {
		return this.eliqNumberPipe.transform(cost, 'cost')
	}

	private energyPipe(energy: number): string {
		if (energy === null) {
			return null as any
		}
		return this.eliqNumberPipe.transform(energy / 1000, 'energy')
	}
}
