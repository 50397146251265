import { Component, OnInit, Input, OnChanges } from '@angular/core'
import { ConsumptionHelperService } from '@eliq/core'
import { EliqNumberPipe } from '@eliq/core'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { NgIf, NgFor } from '@angular/common'
import { EnvironmentService } from '@eliq/data-access'

class OutputObject {
	constructor(
		public name: string,
		public leftValue: string,
		public rightValue: string,
	) {}
}

@Component({
	selector: 'eliq-costs-popper',
	templateUrl: './costs-popper.component.html',
	styleUrls: ['./costs-popper.component.css'],
	standalone: true,
	imports: [NgIf, NgFor, TranslateModule],
})
export class CostsPopperComponent implements OnInit, OnChanges {
	@Input() cost: Map<string, number[]>
	@Input() energy: Map<string, number[]>
	@Input() isCostHidden: boolean

	public hasAdditionalPopperInfo = false
	public enableAdditionalPopperInfo = false

	outputs: OutputObject[] = []

	constructor(
		private consHelper: ConsumptionHelperService,
		private eliqNumberPipe: EliqNumberPipe,
		private translate: TranslateService,
		private env: EnvironmentService,
	) {}

	ngOnInit() {
		// For now let's only enable additional popper info for santander
		if (this.env.getClientName().toLowerCase().includes('santander')) {
			this.enableAdditionalPopperInfo = true
		}

		// If additional popper info is enabled, we check if we have the translation also, otherwise we hide it like if it was disabled.
		if (this.enableAdditionalPopperInfo) {
			const additionalPopperInfoKey =
				'home_consumption_summary_month.additional_popover_info'
			if (
				// If translate.instant returns the original key then the key doesn't exist.
				this.translate.instant(additionalPopperInfoKey) !==
				additionalPopperInfoKey
			) {
				this.hasAdditionalPopperInfo = true
			}
		}

		this.setup()
	}

	ngOnChanges() {
		this.setup()
	}

	private setup() {
		this.outputs = []
		// NOTE!! This component should work if only one of the cost, energy inputs are existing!!
		const mapToUse = this.energy ? this.energy : this.cost
		if (!mapToUse) return

		mapToUse.forEach((arr, key) => {
			const values = [] as string[]

			if (this.cost) {
				values.push(
					this.eliqNumberPipe.transform(
						this.consHelper.summarizeArray(this.cost.get(key)!),
						'cost',
					),
				)
			}

			if (this.energy) {
				values.push(
					this.eliqNumberPipe.transform(
						this.consHelper.summarizeArray(this.energy.get(key)!) / 1000,
						'energy',
					),
				)
			}

			while (values.length < 2) values.push(undefined as any)

			this.outputs.push(new OutputObject(key, values[0], values[1]))
		})
	}
}
