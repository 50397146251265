<div class="container">
	<div *ngIf="status === 'walkthrough'">
		<div class="x" (click)="closeClicked()">
			<eliq-x-button></eliq-x-button>
		</div>

		<p
			class="primary-p2 energy-advisor-walkthrough-tip-count"
			translate
			[translateParams]="{current: currentAdviceIndex+1, max: totalAdvices}"
		>
			energy_advisor.tip_count
		</p>
		<eliq-tip-modal
			[enableDiscardTab]="enableDiscardTab"
			[advice]="advices[currentAdviceIndex]"
			(moveTo)="moveToClicked($event)"
		></eliq-tip-modal>
	</div>

	<div *ngIf="status === 'patching'">
		<div class="loading-container">
			<eliq-spinner size="medium"></eliq-spinner>
		</div>
	</div>

	<div class="done-container" *ngIf="status === 'success'">
		<p
			*ngIf="currentAdviceIndex+1 === totalAdvices; else savedYourProgressBlock"
			class="primary-p2"
			translate
		>
			energy_advisor.that_was_all_new_tips
		</p>
		<eliq-basic-button
			class="done-button"
			(clicked)="doneClicked()"
			label="common.button_done"
		></eliq-basic-button>

		<ng-template #savedYourProgressBlock>
			<p class="primary-p2" translate>energy_advisor.we_saved_your_progress</p>
		</ng-template>
	</div>

	<div class="done-container" *ngIf="status === 'failure'">
		<p class="primary-p2">
			{{('common.something_went_wrong' | translate) + ' ' + ('common.please_try_again_later' |
      translate)}}
		</p>
		<eliq-basic-button
			class="done-button"
			(clicked)="doneClicked()"
			label="common.button_done"
		></eliq-basic-button>
	</div>
</div>
