import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core'
import {
	ConsumptionHandlerService,
	ConsumptionHelperService,
	CoreDataStoreService,
	FuelType,
	PeriodType,
	ResolutionType,
} from '@eliq/core'
import { DateHelper } from '@eliq/util'

import { Period } from '@eliq/core'
import { forkJoin, Observable, of, Subscription } from 'rxjs'
import { map, take, tap } from 'rxjs/operators'
import { ConsumptionComponent } from '../consumption/consumption.component'

@Component({
	selector: 'eliq-consumption-container',
	templateUrl: './consumption-container.component.html',
	styleUrls: ['./consumption-container.component.css'],
	standalone: true,
	imports: [ConsumptionComponent],
})
export class ConsumptionContainerComponent
	implements OnInit, OnChanges, OnDestroy
{
	@Input() locId: number
	@Input() resolutionType: ResolutionType
	@Input() periodType: PeriodType
	@Input() periodToDate: Date
	@Input() fuels: string[]
	@Input() period: Period
	@Input() units: string[]
	@Input() options: any = {} // default to an object at least so we dont get undefined errors if options arent passed in
	@Input() isCostHidden: boolean

	@Output() loading = new EventEmitter<boolean>()

	// to pass to presentational consumption component
	public date: Date
	public consumptionCost: Map<string, number[]>
	public consumptionEnergy: Map<string, number[]>
	public forecastCost: Map<string, number[]>
	public forecastEnergy: Map<string, number[]>
	public forecastCostNextMonth: Map<string, number[]>
	public forecastEnergyNextMonth: Map<string, number[]>
	public previousPeriodConsumption: number // the unit will be inferred by the presentational component
	public similarHomesConsumption: number
	public isEstimated = false // whether to show "Estimated consumption" title instead.

	// internal variables
	public loaded = false
	private dateCalc = DateHelper.getInstance()
	private setupDone = false
	private sub: Subscription

	constructor(
		private consService: ConsumptionHandlerService,
		private consHelper: ConsumptionHelperService,
		private coreDS: CoreDataStoreService,
	) {}

	ngOnInit() {
		this.setup()
		this.setupDone = true
	}

	ngOnChanges() {
		if (this.setupDone) {
			// if we receive changes after the initial setup is done, we need to redo the setup with the new arguments
			this.setup()
		}
	}

	ngOnDestroy() {
		this.sub?.unsubscribe()
	}

	private setup() {
		if (this.sub) {
			this.sub?.unsubscribe()
		}
		this.setLoaded(false)
		this.clearPassedVars()
		this.date = this.period.getFirstDate()

		this.period = new Period(PeriodType.Month, this.period.getFirstDate())
		const fromDate = new Date(
			this.dateCalc.getApiCompliantFirstOrLastDateOfPeriod(
				this.period.getFirstDate(),
				this.period.getPeriodType(),
				true,
			),
		)
		const toDate = this.dateCalc.addOfPeriod(
			this.period.getPeriodType(),
			1,
			this.period.getFirstDate(),
		)

		const prevFromDate = this.dateCalc.subOfPeriod(
			'month',
			1,
			this.period.getFirstDate(),
		)
		const prevToDate = this.period.getFirstDate()

		const nextFromDate = this.dateCalc.addOfPeriod(
			'month',
			1,
			this.period.getFirstDate(),
		)
		const nextToDate = this.dateCalc.addOfPeriod(
			'month',
			2,
			this.period.getFirstDate(),
		)

		const requests = [] as Observable<any>[]
		const hasCost = this.units.includes('cost')
		const hasEnergy = this.units.includes('energy')

		if (hasCost) {
			// we get consumption and previous period for consumption
			const cons = this.consService.getConsumptionForFuels(
				this.locId,
				this.fuels,
				'cost',
				this.resolutionType,
				fromDate,
				toDate,
			)
			const prevCons = this.consService.getConsumptionForFuels(
				this.locId,
				this.fuels,
				'cost',
				this.resolutionType,
				prevFromDate,
				prevToDate,
			)

			requests.push(
				forkJoin({ currCons: cons, prevCons: prevCons }).pipe(
					tap((res) => {
						this.consumptionCost = res.currCons
						const sumResult = this.sumMapToOtherMapsIndexes(
							res.prevCons,
							res.currCons,
						)
						if (sumResult) {
							this.previousPeriodConsumption = sumResult
						}
					}),
					take(1), // in order for the forkjoin to complete itself
				),
			)

			if (this.options.forecastThisMonth) {
				const fore = this.consService.getForecastForFuels(
					this.locId,
					this.fuels,
					'cost',
					this.resolutionType,
					fromDate,
					toDate,
				)
				requests.push(
					fore.pipe(
						tap((res) => {
							const forecastCost = this.handleForecast(res)
							if (forecastCost) {
								this.forecastCost = forecastCost
							}
						}),
					),
				)
			} else if (this.options.forecastNextMonth) {
				const fore = this.consService.getForecastForFuels(
					this.locId,
					this.fuels,
					'cost',
					this.resolutionType,
					nextFromDate,
					nextToDate,
				)
				requests.push(
					fore.pipe(
						tap((res) => {
							const forecastCostNextMonth = this.handleForecast(res)
							if (forecastCostNextMonth) {
								this.forecastCostNextMonth = forecastCostNextMonth
							}
						}),
					),
				)
			}
		}

		if (hasEnergy) {
			const cons = this.consService.getConsumptionForFuels(
				this.locId,
				this.fuels,
				'energy',
				this.resolutionType,
				fromDate,
				toDate,
			)

			// if we know that we have cost values, then we are doing the prevperiod comparison with that instead of energy, so this is undefined
			const prevCons = hasCost
				? of(undefined)
				: this.consService.getConsumptionForFuels(
						this.locId,
						this.fuels,
						'energy',
						this.resolutionType,
						prevFromDate,
						prevToDate,
				  )

			// only get similar homes if its specified in the options argument, else undefined
			const simhomesRequest =
				this.consService.getSimilarHomesConsumptionForFuels(
					this.locId,
					this.fuels,
					'energy',
					ResolutionType.Month,
					fromDate,
					this.periodToDate,
				)
			const simhomes = this.options.showSimhomes
				? simhomesRequest
				: of(undefined)

			requests.push(
				forkJoin({
					currCons: cons,
					prevCons: prevCons,
					simhomes: simhomes,
				}).pipe(
					tap((res) => {
						this.consumptionEnergy = res.currCons
						if (res.prevCons)
							this.previousPeriodConsumption =
								this.sumMapToOtherMapsIndexes(res.prevCons, res.currCons) || 0
						if (res.simhomes) {
							// If we're monthly resolution then sumMapToIndexes would just give us the first entry of the map
							this.similarHomesConsumption =
								(this.resolutionType !== ResolutionType.Month
									? this.sumMapToOtherMapsIndexes(res.simhomes, res.currCons)
									: this.consHelper.summarizeArraysInMap(res.simhomes) *
									  (Math.min(30, this.periodToDate.getDate()) / 30)) || 0
						}
					}),
					take(1), // in order for the forkjoin to complete itself
				),
			)

			if (this.options.forecastThisMonth) {
				const fore = this.consService.getForecastForFuels(
					this.locId,
					this.fuels,
					'energy',
					this.resolutionType,
					fromDate,
					toDate,
				)
				requests.push(
					fore.pipe(
						tap((res) => {
							return (this.forecastEnergy =
								this.handleForecast(res) || this.forecastEnergyNextMonth)
						}),
					),
				)
			} else if (this.options.forecastNextMonth) {
				const fore = this.consService.getForecastForFuels(
					this.locId,
					this.fuels,
					'energy',
					this.resolutionType,
					nextFromDate,
					nextToDate,
				)
				requests.push(
					fore.pipe(
						tap(
							(res) =>
								(this.forecastEnergyNextMonth =
									this.handleForecast(res) || this.forecastEnergyNextMonth),
						),
					),
				)
			}
		}

		const isEstimatedRequests = [] as Observable<any>[]
		for (const fuel of this.fuels) {
			isEstimatedRequests.push(
				this.coreDS.fuelIsEstimated(fuel as FuelType, ['consumption']),
			)
		}

		requests.push(
			forkJoin(isEstimatedRequests).pipe(
				take(1),
				map((isEstimatedArr) =>
					isEstimatedArr.some((isEstimated) => isEstimated),
				),
				tap((isEstimated) => {
					this.isEstimated = isEstimated
				}),
			),
		)

		this.sub = forkJoin(requests).subscribe(
			(_) => {
				this.setLoaded(true)
			},
			(err) => {
				// always set loaded to true. handle errors in each individual observable
				this.setLoaded(true)
			},
		)
	}

	private setLoaded(loaded: boolean) {
		// Checking if load status has changed to avoid emiting same status multiple times
		if (loaded !== this.loaded) {
			this.loaded = loaded
			this.loading.emit(!loaded)
		}
	}

	private clearPassedVars() {
		this.date = undefined as any
		this.consumptionCost = undefined as any
		this.consumptionEnergy = undefined as any
		this.forecastCost = undefined as any
		this.forecastEnergy = undefined as any
		this.forecastCostNextMonth = undefined as any
		this.forecastEnergyNextMonth = undefined as any
		this.previousPeriodConsumption = undefined as any
		this.similarHomesConsumption = undefined as any
	}

	/**
	 * Summarizes each array in the sumMap to the last non-null index value in the corresponding array in indexMap
	 * @param sumMap the map to summarize
	 * @param indexMap the map to get indexes from, which is the basis of the summary being made
	 * @returns one single number for all arrays summarized together
	 */
	private sumMapToOtherMapsIndexes(
		sumMap: Map<string, number[]>,
		indexMap: Map<string, number[]>,
	): number | undefined {
		if (!sumMap || !indexMap) return undefined

		const keys = [] as string[]
		sumMap.forEach((_arr, key) => {
			keys.push(key)
		})

		// if there is any key that corresponds to a null/undefined value (instead of array)
		if (keys.some((key) => !sumMap.get(key))) return undefined

		let prevPeriodSum = 0
		// map the prevCons array to a number that is passed to the child.
		sumMap.forEach((consumption, fuel) => {
			const cutOff = this.consHelper.getIndexOfLastEntryWithValue(
				indexMap.get(fuel) as number[],
			)
			consumption.forEach((p, i) => {
				if (p !== null && i <= cutOff) {
					prevPeriodSum += p
				}
			})
		})

		return prevPeriodSum
	}

	private handleForecast(forecast: Map<string, number[]>) {
		const forecasts: number[][] = []
		forecast.forEach((arr) => forecasts.push(arr))

		if (
			forecasts.some((forecastArray) =>
				this.consHelper.arrayHasNonNullZeroValue(forecastArray),
			)
		) {
			// if there is some array in here with at least one non-null, non-zero value, then we can return it as it is valuable forecast data.
			return forecast
		} else {
			// else, there is no forecast, assign undefined!!
			return undefined
		}
	}
}
