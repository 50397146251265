// @ts-nocheck

import { Component, Input, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { EnergyAdvisorApiService } from '../../services/energy-advisor-api.service'
import { AdviceStatus, APIAdvice } from '../../models/api-advice.model'
import { forkJoin, Subscription } from 'rxjs'
import { tap } from 'rxjs/operators'
import { TipsTrackingService } from '../../tracking/tips-tracking.service'
import {
	TipAddedToTodoFrom,
	TipDiscardedFrom,
} from '../../tracking/tips-tracking-events.model'
import { BasicButtonComponent } from '@eliq/ui'
import { SpinnerComponent } from '@eliq/ui'
import { TipModalComponent } from '../tip-modal/tip-modal.component'
import { TranslateModule } from '@ngx-translate/core'
import { XButtonComponent } from '@eliq/ui'
import { NgIf } from '@angular/common'

@Component({
	selector: 'eliq-tip-walkthrough-modal-container',
	templateUrl: './tip-walkthrough-modal-container.component.html',
	styleUrls: ['./tip-walkthrough-modal-container.component.scss'],
	standalone: true,
	imports: [
		NgIf,
		XButtonComponent,
		TranslateModule,
		TipModalComponent,
		SpinnerComponent,
		BasicButtonComponent,
	],
})
export class TipWalkthroughModalContainerComponent implements OnInit {
	@Input() advices: APIAdvice[]
	@Input() locId: number

	public status = 'walkthrough'

	private map: Map<APIAdvice, string> = new Map()
	private completedUpdates: APIAdvice[] = []
	private ongoingRequest: Subscription

	public currentAdviceIndex: number
	public totalAdvices: number
	public enableDiscardTab = true

	constructor(
		public dialogRef: MatDialogRef<TipWalkthroughModalContainerComponent>,
		private api: EnergyAdvisorApiService,
		private tracking: TipsTrackingService,
	) {}

	ngOnInit(): void {
		this.currentAdviceIndex = 0
		this.totalAdvices = this.advices.length

		this.dialogRef.backdropClick().subscribe((_) => {
			if (this.status === 'walkthrough') this.closeClicked()
			else {
				if (this.status === 'patching') {
					this.ongoingRequest?.unsubscribe()
				}
				// we're clicking outside of the modal after we have completed the walkthroug,
				// so this is the same as actually clicking done. since we are done, right, and we click ourselves
				// out of the modal
				this.doneClicked()
			}
		})
	}

	moveToClicked(moveToCategory: string) {
		this.trackTipDecision(moveToCategory)

		this.map.set(this.advices[this.currentAdviceIndex], moveToCategory)
		if (this.currentAdviceIndex === this.advices.length - 1) {
			// this was the last one!
			this.tracking.viewNewTipsFinished()
			this.patchUpdatedAdvices(this.map)
		} else {
			// more advices left
			this.currentAdviceIndex += 1
		}
	}

	private trackTipDecision(moveToCategory: string) {
		switch (moveToCategory) {
			case 'category_todo':
				return this.tracking.tipAddedToTodo(TipAddedToTodoFrom.NewTipsPopup)
			case 'category_discarded':
				return this.tracking.tipDiscarded(TipDiscardedFrom.NewTipsPopup)
			default:
				return
		}
	}

	closeClicked() {
		this.tracking.viewNewTipsCancelled()
		this.patchUpdatedAdvices(this.map)
	}

	doneClicked() {
		this.dialogRef.close(this.completedUpdates)
	}

	private patchUpdatedAdvices(adviceMap: Map<APIAdvice, string>) {
		if (this.map.size === 0) {
			this.dialogRef.close(undefined)
			return
		}

		this.status = 'patching'
		const requests = []
		adviceMap.forEach((category, advice) => {
			requests.push(
				this.api
					.postAdviceStatus(
						this.locId,
						advice.id,
						this.getAdviceStatusFromCategory(category),
					)
					.pipe(
						tap((completion) => {
							advice.status = this.getAdviceStatusFromCategory(category)
							this.completedUpdates.push(advice)
						}),
					),
			)
		})

		this.ongoingRequest = forkJoin(requests).subscribe(
			(res) => {
				this.status = 'success'
			},
			(err) => {
				this.status = 'failure'
			},
		)
	}

	private getAdviceStatusFromCategory(category: string): AdviceStatus {
		if (category === 'category_todo') return AdviceStatus.SaveForLater
		else if (category === 'category_done') return AdviceStatus.Implemented
		else if (category === 'category_discarded') return AdviceStatus.NotRelevant
	}
}
