export enum TipsEvent {
	ViewNewTips = 'tips_view_new_tips',
	ViewNewTipsCancelled = 'tips_view_new_tips_cancelled',
	ViewNewTipsFinished = 'tips_view_new_tips_finished',
	ViewAllTips = 'tips_view_all_tips',
	CategoryTodoClicked = 'tips_category_todo_clicked',
	CategoryDoneClicked = 'tips_category_done_clicked',
	CategoryDiscardedClicked = 'tips_category_discarded_clicked',
	TipAddedToTodo = 'tips_tip_added_to_todo',
	TipDone = 'tips_tip_done',
	TipDiscarded = 'tips_tip_discarded',
	TipReadMoreClicked = 'tips_tip_read_more_clicked',
}

export enum TipAddedToTodoFrom {
	NewTipsPopup = 'new_tips_popup',
	NewTipsCarousel = 'new_tips_carousel',
	CategoryDiscarded = 'category_discarded',
	CategoryDone = 'category_done',
}

export enum TipDoneFrom {
	Checkbox = 'checkbox',
	ReadMorePopup = 'read_more_popup',
}

export enum TipDiscardedFrom {
	NewTipsPopup = 'new_tips_popup',
	NewTipsCarousel = 'new_tips_carousel',
	CategoryTodo = 'category_todo',
}

export enum TipReadMoreClickedFrom {
	NewTipsCarousel = 'new_tips_carousel',
	CategoryTodo = 'category_todo',
	CategoryDone = 'category_done',
	CategoryDiscarded = 'category_discarded',
}

export class ViewNewTipsEvent {
	v: number
}

export class ViewNewTipsCancelledEvent {
	v: number
}

export class ViewNewTipsFinishedEvent {
	v: number
}

export class ViewAllTipsEvent {
	v: number
}

export class CategoryTodoClickedEvent {
	v: number
}

export class CategoryDoneClickedEvent {
	v: number
}

export class CategoryDiscardedClickedEvent {
	v: number
}

export class TipAddedToTodoEvent {
	v: number
	from: TipAddedToTodoFrom
}

export class TipDoneEvent {
	v: number
	from: TipDoneFrom
}

export class TipDiscardedEvent {
	v: number
	from: TipDiscardedFrom
}

export class TipReadMoreClickedEvent {
	v: number
	from: TipReadMoreClickedFrom
}
